// NOTE I'll just put it here
import smoothscroll from 'smoothscroll-polyfill';
smoothscroll.polyfill();

import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto-slab/400.css";
import "@fontsource/calligraffitti/400.css";

import Rails from "@rails/ujs"
// TODO! clean this up: import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// TODO! well, remove, probably, from the project: Turbolinks.start()
//       since (a) it screws up simple (public) pages navigation
//             (b) it loses the web page state when coming back from an external site
ActiveStorage.start()

import "controllers"
