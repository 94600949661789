// TODO all these must come from the server (through data attrs on controller el)
const MAIL_SUBJECT_PREFIX = 'Re: ';
const MAIL_ADDRESS = 'work@costashapiro.com'; // TODO! softcode this

import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    let el = document.getElementById(location.hash.substr(1));
    let contact_el = document.getElementById('contact');
    el.appendChild(contact_el);
  }

  writing() {
    let contact_el = document.getElementById('contact');
    // TODO! unmagic that number
    let mail_subject = MAIL_SUBJECT_PREFIX + contact_el.parentElement.textContent.split('\n')[6];
    let mail_body = contact_el.querySelector('.message').value;
    // BUGBUGBUG in iOS: https://developer.apple.com/forums/thread/681023
    location.href = `mailto:${encodeURIComponent(MAIL_ADDRESS)}?subject=${encodeURIComponent(mail_subject)}&body=${encodeURIComponent(mail_body).replace('%0A', '%0D%0A')}`;
  }
}
