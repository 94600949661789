// TODO all these must come from the server (through data attrs on controller el)
const MAIL_SUBJECT = 'Nice contact page';
const MAIL_ADDRESS = 'work@costashapiro.com';
const MAIL_BODY_OPENING = "Hi Costa,\n\nI've come across this at your works page:\n\n";
const MAIL_BODY_MIDDLE = "\n\nand here's what I'm thinking:\n\n";
const SCROLL_ANI_STEP_MS = 15;
const SCROLL_ANI_INIT_VELO = 0.01;
const SCROLL_ANI_ACCEL_RATIO = 1.11;

import { Controller } from "stimulus"

export default class extends Controller {

  pageShow() {
    // TODO a nicer solution would probably be keeping the scroll position
    //       on pagehide, and then, just restoring it, but this is good enough
    this._scrollInit();
  }

  scrolled() {
    let scrollPosition = this._scrollPosition();
    // TODO teh colours must be taken from CSS
    if (scrollPosition < 0.49) {
      this.element.style.backgroundColor = `rgba(48, 24, 96, ${(0.49 - scrollPosition)/0.49})`;
    } else if (scrollPosition > 0.51) {
      this.element.style.backgroundColor = `rgba(96, 24, 48, ${(scrollPosition - 0.51)/0.49})`;
    } else {
      // TODO making it completely transparent makes iOS Safari go crazy with scroll snapping
      this.element.style.backgroundColor = 'rgba(255, 255, 255, 0.01)';
    }
    let grad = Math.abs(0.5 - scrollPosition)/0.5*255;
    this.element.style.color = `rgb(${grad}, ${grad}, ${grad})`;
    this._scrollNext();
  }

  clicked(event) {
    let el = event.target;
    if(el.tagName == 'P' || el.tagName == 'LI') {
      let contact_el = document.getElementById('contact');
      el.appendChild(contact_el);
      contact_el.querySelector('.message').focus();
      setTimeout(() => contact_el.classList.add('open'), 0);
    } else {
      let rightLeft = 1;
      if (event.x < this.element.offsetLeft + this._scrollStep()/10) {
        rightLeft = -1;
      } else if (event.x < this.element.offsetLeft + this.element.offsetWidth - this._scrollStep()/10) {
        return;
      }
      this.element.scrollBy({
        top: 0,
        left: rightLeft*this._scrollStep(),
        behavior: 'smooth'
      });
    }
  }

  closed() {
    document.getElementById('contact').classList.toggle('open');
  }

  writing() {
    let contact_el = document.getElementById('contact');
    let mail_body =
        MAIL_BODY_OPENING +
        contact_el.parentElement.textContent.split('\n')[0] +
        MAIL_BODY_MIDDLE +
        contact_el.querySelector('.message').value;
    // BUGBUGBUG in iOS: https://developer.apple.com/forums/thread/681023
    location.href = `mailto:${encodeURIComponent(MAIL_ADDRESS)}?subject=${encodeURIComponent(MAIL_SUBJECT)}&body=${encodeURIComponent(mail_body).replace('%0A', '%0D%0A')}`;
  }


  _scrollInit() {
    this.element.classList.toggle('scroll-snap');
    this.element.scrollLeft = 0;
    this._scrollState = 'scrolling-right';
    this._scrollRatio = 0;
    this._scrollVelo = SCROLL_ANI_INIT_VELO;
    delete this._scrollingTo;
    this._scrollNext();
  }

  _scrollStep() {
    return (this.element.scrollWidth - this.element.clientWidth) / 2;
  }

  _scrollPosition() {
    return this.element.scrollLeft / (this.element.scrollWidth - this.element.clientWidth);
  }

  _scrollTo(ratio) {
    if (ratio > 1) {
      ratio = 1.0;
    }
    this._scrollingTo = parseInt(ratio * (this.element.scrollWidth - this.element.clientWidth));

    this.element.scrollTo({
      top: 0,
      left: this._scrollingTo,
      behavior: 'instant'
    });
  }

  _scrollNext() {
    if (!this._scrollState || (this._scrollingTo && this.element.scrollLeft != this._scrollingTo)) {
      return;  // NOTE will be scrolling some more, hopefully
    }
    if (this._scrollState == 'scrolling-right') {
      if (this._scrollPosition() > 0.99) {
        this._scrollState = 'scrolling-left';
        this._scrollVelo = - this._scrollVelo;
        this._scrollRatio += this._scrollVelo;
        setTimeout(() => this._scrollTo(this._scrollRatio), SCROLL_ANI_STEP_MS);
      } else {
        this._scrollVelo *= SCROLL_ANI_ACCEL_RATIO;
        this._scrollRatio += this._scrollVelo;
        setTimeout(() => this._scrollTo(this._scrollRatio), SCROLL_ANI_STEP_MS);
      }
    } else if (this._scrollState == 'scrolling-left') {
      if (this._scrollPosition() < 0.5) {
        this._scrollState = 'scrolling-final';
        this._scrollRatio = 0.5;
        setTimeout(() => this._scrollTo(this._scrollRatio), SCROLL_ANI_STEP_MS);
      } else {
        this._scrollVelo /= SCROLL_ANI_ACCEL_RATIO;
        this._scrollRatio += this._scrollVelo;
        setTimeout(() => this._scrollTo(this._scrollRatio), SCROLL_ANI_STEP_MS);
      }
    } else if (this._scrollState == 'scrolling-final') {
      delete this._scrollState;
      setTimeout(() => this.element.classList.toggle('scroll-snap'), 0);
      setTimeout(() => this._scrollTo(0.6), 1);

      // TODO obviously, not ideal
      this.element.getElementsByClassName('tense')[1].append(document.getElementById('contact'));
    }
  }
}
